import { getBrowser } from "./test-media/test-media.service";
import { buildId, POST } from './common';
import TagsAutocompleter from "./TagsAutocompleter";
import track, { EVENT_TYPES } from "./amplitude";
import { closeDrawer } from "./rightPanel";

$(document).ready(() => {

  track(EVENT_TYPES.UI.PAGE_VIEWED);

  // Init autocompletor
  $('.v2-autocompletor--autoload').each((k, elem) => {

    const $elem = $(elem);

    if ($elem.prop('autocompletor')) {
      return;
    }

    let $autocompletor = new TagsAutocompleter($elem);
    $autocompletor.init(true);
  });


  $('.export-as-csv').on('click', function() {
    let index = $(this).attr('index');
    // eslint-disable-next-line no-undef
    ExcellentExport.csv(this, 'table-symptoms-' + index, ';', '\n');
    return;
  });


  let browser = getBrowser();

  let d = localStorage.getItem('browser-band');

  if (!d) {
    if ($.inArray(browser, ['Chrome', 'Firefox']) === -1) {
      let $band = $('.browser-band');
      $band.find('.browser').html(browser);
      $band.show();

      $band.find('.close-band').on('click', function() {

        localStorage.setItem($band.attr('class'), 'dismissed');
        $band.fadeOut(200);
      });
    }
  }

  initModal($('.popin-action,.drawer'));

  // Popins
  $('.popin-action,.drawer').find('.close-box').on('click', () => {

    // Popins
    initModal($('.popin-action,.drawer'));

    $('.restore-password').on('click', async (e) => {

      let $elem = $(e.target);

      $elem.prop('disabled', true);

      await POST(Routing.generate('api_user_reset_password', {
        user: $elem.attr('data-user')
      }));

      document.location.reload();


    });
  });

});

export const initModal = ($modal) => {

  $modal.find('.close-box,.close-button').off('click').on('click', (e) => {

    let $e = $(e.target);

    let modal = $e.closest('.popin-action');

    if (modal.length === 0) {
      modal = $e.closest('.drawer');
      closeDrawer(modal);
    } else {
      modal.fadeOut(200);
    }

    if (modal.get(0) && typeof modal.get(0).onClose === 'function') {
      modal.get(0).onClose(e);
    }

    if (modal.attr('id')) {
      track(EVENT_TYPES.UI.MODAL_DISMISSED, {
        element: modal.attr('id'),
      });
    }
  });
};


export const getMedicalDocumentEditUrl = (medicalDocument,patient,consultation) =>
{
  return medicalDocument.isCerfa ? Routing.generate('webapp_cerfa_create', {
    patient: patient.id,
    consultation: consultation ? consultation.id : null,
    document: buildId('medical_documents',medicalDocument.id)
  }) : Routing.generate('app_medical_document_manage', {
    patient: patient.id,
    type: medicalDocument.type.canonical,
    consultation: consultation ? consultation.id : null,
    document: medicalDocument.id
  });
};
