import { displayError } from "../common";
import { SoundMeter } from "./soundmetter";


/**
 *
 * @return {MediaDevices}
 */
export function isBrowserCompatible() {
  return navigator.mediaDevices;
}


/**
 * Launch Audio test
 *
 * @param $tester
 */
export function launchAudio($tester) {

  let q = $.Deferred();


  let mediaConstraint = {
    audio: true,
    video: false
  };
  navigator.mediaDevices.getUserMedia(mediaConstraint).then(function(stream) {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    let context = new AudioContext();
    window.stream = stream;
    let soundMeter = window.soundMeter = new SoundMeter(context);

    soundMeter.connectToSource(stream, function(e) {
      if (e) {
        displayError(e);
        return;
      }

      setInterval(function() {

        let percent = soundMeter.instant*100;

        $tester.css('width', (percent + '%'));

        if(percent >= 0.1) {
          q.resolve();
        }

      }, 200);

    });
  }).catch(function(e) {
    displayError(e);

    q.reject(e);

  });

  return q.promise();
}



/**
 *
 * @param $video
 * @param inTest
 *
 * @return {Promise}
 */
export function launchVideo($video,inTest) {

  let q = $.Deferred();

  var mediaConstraint = {
    audio: !!inTest,
    video: true
  };

  navigator.mediaDevices.getUserMedia(mediaConstraint).then(function(stream) {

    $video.css('height', 'auto');
    let vidElement = $video[0];

    vidElement.srcObject = stream;
    vidElement.onloadedmetadata = () => {
      vidElement.play();
    };

    q.resolve();

  }).catch(function(e) {
    console.log(e);
    displayError(e);
    q.reject(e);
  });

  return q.promise();

}


export function playAudio($audio) {
  $audio.get(0).play();
}


/**
 *
 * @return {string}
 */
export function getBrowser() {
  if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 )
  {
    return 'Opera';
  }
  else if(navigator.userAgent.indexOf("Chrome") != -1 )
  {
    return 'Chrome';
  }
  else if(navigator.userAgent.indexOf("Safari") != -1)
  {
    return 'Safari';
  }
  else if(navigator.userAgent.indexOf("Firefox") != -1 )
  {
    return ('Firefox');
  }
  else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
  {
    return ('Internet Explorer');
  }
  else
  {
    return ('unknown');
  }
}