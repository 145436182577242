$(document).ready(() => {

  const $body = $('body');

  initTabs($body, $body);
});


const scrollElement = ($container, scrollOffset, add) => {
  if (add) {
    $container[0].scrollLeft += scrollOffset;
  } else {
    $container[0].scrollLeft = scrollOffset;
  }
};


export const initTabs = ($headerContainer, $container, onUpdate = () => {
}) => {

  const $links = $headerContainer.find('.tab-link');

  $links.each((index, tab) => {

    const hash = $(tab).attr('target-tab');

    const pan = $container.find(`[tab="${hash}"]`);

    pan.find('a').each((index, elem) => {
      updateLinks(elem, hash);
    });

  });


  $(window).on('resize', () => {
    handleTabsScroll($headerContainer);
  });

  $links.off('click').on('click', (e) => {

    const tab = updateHash(e.target, $container, $headerContainer);

    scrollElement($headerContainer, e.currentTarget.closest('li').offsetLeft - 20, false);

    handleTabsScroll($headerContainer);

    if (tab) {
      onUpdate(tab);
    }
  });

  const handled = handleTabs($headerContainer, $container);

  $headerContainer.off('scroll').on('scroll', () => {
    handleTabsScroll($headerContainer);
  });

  return handled ?? $links.first().attr('target-tab');

};


const handleTabsScroll = ($container) => {

  $container.parent().find('.left').remove();
  $container.parent().find('.right').remove();

  const container = $container[0];

  const hasScrollbar = container.scrollWidth > container.offsetWidth;
  const showArrowLeft = (hasScrollbar && container.scrollLeft > 0);
  const showArrowRight = (hasScrollbar && container.scrollLeft < container.scrollWidth - container.offsetWidth);

  if (showArrowLeft) {
    const $button = $(`<span class="arrow left" >
<span class="material-icons" >arrow_left</span>
</span>`);

    $container.parent().append($button);

    $button.on('click', () => {

      scrollElement($container, -100, true);

    });

  }

  if (showArrowRight) {
    const $button = $(`<span class="arrow right" >
<span class="material-icons" >arrow_right</span>
</span>`);

    $container.parent().append($button);

    $button.on('click', () => {

      scrollElement($container, 100, true);

    });

  }

  if (!hasScrollbar) {
    return;
  }


  let isDown = false;
  let startX;
  let scrollLeft;

  $container.on('mousedown', (e) => {
    isDown = true;
    $container[0].classList.add('active');
    startX = e.pageX - $container[0].offsetLeft;
    scrollLeft = $container[0].scrollLeft;
  });
  $container.on('mouseup', (e) => {
    isDown = false;
    $container[0].classList.remove('active');
    e.preventDefault();
  });
  $container.on('mousemove', (e) => {
    if (!isDown) {
      return;
    }
    e.preventDefault();

    const x = e.pageX - $container[0].offsetLeft;
    const walk = (x - startX);
    $container[0].style.scrollBehavior = 'initial';
    $container[0].scrollLeft = scrollLeft - walk;

    $container[0].style.scrollBehavior = 'smooth';
  });


};


/**
 *
 * @param elem
 * @param $container
 * @param $headerContainer
 */
function updateHash(elem, $container, $headerContainer) {

  let $elem = $(elem);

  const $target = $elem.attr('target-tab');

  if (!$target) {
    return;
  }


  $headerContainer.find('.tab-link').removeClass('active');
  $elem.addClass('active');

  $container.find('.tab').removeClass('show').addClass('hidden');
  $container.find('[tab="' + $target + '"]').removeClass('hidden').addClass('show');

  const existingHash = document.location.hash;


  if (!existingHash || !existingHash.startsWith(`#${$target}`)) {
    document.location.hash = $target;
  }

  return $target;

}

/**
 *
 * @param elem
 * @param hash
 */
function updateLinks(elem, hash) {

  let link = $(elem).attr('href');

  if (!link || link.indexOf('#') !== -1) {
    return;
  }

  link = link + '#' + hash;

  $(this).attr('href', link);

}


/**
 *
 * @param $headerContainer
 * @returns {string|undefined}
 */
function handleTabs($headerContainer) {

  if ($headerContainer.find('.nav-tabs').length === 0 && !$headerContainer.hasClass('nav-tabs')) {
    return;
  }

  let urlHash = document.location.hash?.substring(1);

  if (urlHash?.includes('--')) {
    const exploded = urlHash.split('--');
    urlHash = exploded[0];
  }

  const hash = urlHash ? urlHash : $headerContainer.find('.tab-default').attr('target-tab');

  if (!hash) {
    return;
  }

  const $tab = $headerContainer.find(`.nav-link[target-tab="${hash}"]`);

  if ($tab.length) {
    $tab.click();

    return hash;
  }
}
